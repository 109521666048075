/* eslint-disable @typescript-eslint/no-explicit-any */
export const Conf: { [key: string]: any } = {
    salesDemoUsers: ['999999001'],
    u2: {
        code: {
            ReachStock: '71',
            UEyes: '69',
            USEN_CART: '***',
        },
        name: {
            ReachStock: 'リーチストック',
            UEyes: 'USEN Camera',
        },
    },
    serviceCode: {
        code: {
            WIFI: 'U60',
            WIFI2: 'U62',
            EYES: 'U40',
            EYES2: 'U04',
            AIR: 'U02',
            MUSIC_STA: 'U10',
            MUSIC_SELF: 'U14',
            MEMBERS: 'UZZ',
            ENTERTAINMENT: 'U70',
            ENTERTAINMENT2: 'U69',
            SIGNAGE: 'U90',
            SIGNAGE2: 'U91',
            UPAYPLUS: 'U51',
        },
        name: {
            SIGNAGE: 'USENサイネージ',
            SIGNAGE2: 'USENサイネージ',
        },
    },
    securityQuestions: [
        {
            value: '0',
            title: '質問を選択してください',
            isHidden: true,
        },
        {
            value: '1',
            title: '卒業した小学校の校名は？',
        },
        {
            value: '2',
            title: '祖父の下の名前は？',
        },
        {
            value: '3',
            title: '初めて行った海外の国は？',
        },
        {
            value: '4',
            title: '本籍地は？',
        },
    ],
    prefectures: [
        {
            value: '00',
            title: '選択してください',
            isHidden: true,
        },
        {
            value: '01',
            title: '北海道',
        },
        {
            value: '02',
            title: '青森県',
        },
        {
            value: '03',
            title: '岩手県',
        },
        {
            value: '04',
            title: '宮城県',
        },
        {
            value: '05',
            title: '秋田県',
        },
        {
            value: '06',
            title: '山形県',
        },
        {
            value: '07',
            title: '福島県',
        },
        {
            value: '08',
            title: '茨城県',
        },
        {
            value: '09',
            title: '栃木県',
        },
        {
            value: '10',
            title: '群馬県',
        },
        {
            value: '11',
            title: '埼玉県',
        },
        {
            value: '12',
            title: '千葉県',
        },
        {
            value: '13',
            title: '東京都',
        },
        {
            value: '14',
            title: '神奈川県',
        },
        {
            value: '15',
            title: '新潟県',
        },
        {
            value: '16',
            title: '富山県',
        },
        {
            value: '17',
            title: '石川県',
        },
        {
            value: '18',
            title: '福井県',
        },
        {
            value: '19',
            title: '山梨県',
        },
        {
            value: '20',
            title: '長野県',
        },
        {
            value: '21',
            title: '岐阜県',
        },
        {
            value: '22',
            title: '静岡県',
        },
        {
            value: '23',
            title: '愛知県',
        },
        {
            value: '24',
            title: '三重県',
        },
        {
            value: '25',
            title: '滋賀県',
        },
        {
            value: '26',
            title: '京都府',
        },
        {
            value: '27',
            title: '大阪府',
        },
        {
            value: '28',
            title: '兵庫県',
        },
        {
            value: '29',
            title: '奈良県',
        },
        {
            value: '30',
            title: '和歌山県',
        },
        {
            value: '31',
            title: '鳥取県',
        },
        {
            value: '32',
            title: '島根県',
        },
        {
            value: '33',
            title: '岡山県',
        },
        {
            value: '34',
            title: '広島県',
        },
        {
            value: '35',
            title: '山口県',
        },
        {
            value: '36',
            title: '徳島県',
        },
        {
            value: '37',
            title: '香川県',
        },
        {
            value: '38',
            title: '愛媛県',
        },
        {
            value: '39',
            title: '高知県',
        },
        {
            value: '40',
            title: '福岡県',
        },
        {
            value: '41',
            title: '佐賀県',
        },
        {
            value: '42',
            title: '長崎県',
        },
        {
            value: '43',
            title: '熊本県',
        },
        {
            value: '44',
            title: '大分県',
        },
        {
            value: '45',
            title: '宮崎県',
        },
        {
            value: '46',
            title: '鹿児島県',
        },
        {
            value: '47',
            title: '沖縄県',
        },
    ],
    //認証ステータス
    verifiedStatus: {
        code: {
            verified: '1',
            unverified: '0',
            awaiting: '2',
        },
    },
    //ユーザーステータス
    userStatus: {
        code: {
            active: '1',
            inactive: '0',
        },
    },
    //ユーザータイプ
    userType: {
        code: {
            parent: 'parent',
            child: 'child',
        },
    },
    //登録ステータス
    registerStatus: {
        code: {
            provisional: '1',
            final: '0',
        },
    },
    //ユーザー権限
    userGrant: {
        code: {
            individual_parent: '0',
            individual_share_admin: '1',
            individual_share_user: '2',
            individual_share_view: '3',
            individual_share_maintenance: '9',
            mother_parent: '10',
            mother_share_admin: '11',
            mother_share_user: '12',
            mother_share_view: '13',
            mother_share_maintenance: '19',
            head_parent: '20',
            head_share_admin: '21',
            head_share_user: '22',
            head_share_view: '23',
            head_share_maintenance: '29',
            branch_parent: '30',
            branch_share_admin: '31',
            branch_share_user: '32',
            branch_share_view: '33',
            branch_share_maintenance: '39',
            temporary: '99',
        },
    },
    shareUserGrantDefine: [
        { value: '1', name: '一般ユーザー' },
        { value: '2', name: '閲覧者' },
        { value: '3', name: '管理者' },
    ],
    shareUserGrant: {
        individual_parent: [
            {
                value: '1',
                title: '一般ユーザー',
            },
            {
                value: '2',
                title: '閲覧者',
            },
            {
                value: '3',
                title: '管理者',
            },
        ],
        mother_parent: [
            {
                value: '11',
                title: '一般ユーザー',
            },
            {
                value: '12',
                title: '閲覧者',
            },
            {
                value: '13',
                title: '管理者',
            },
        ],
        branch_parent: [
            {
                value: '31',
                title: '一般ユーザー',
            },
            {
                value: '32',
                title: '閲覧者',
            },
            {
                value: '33',
                title: '管理者',
            },
        ],
    },
    //秘密の質問
    securityQuestion: {
        code: {
            default: '質問を選択してください',
            first: '卒業した小学校の校名は？',
            second: '祖父の下の名前は？',
            third: '初めて行った海外の国は？',
            fourth: '本籍地は？',
        },
    },
    paymentMethodCode: {
        code: {
            CreditCard: '1',
            AutoTransfer: '2',
            Transfer: '3',
        },
    },
    dragonService: {
        name: {
            uair: 'インターネット・Wi-Fi',
            ueyes: '防犯・監視カメラ',
            umusic: '店舗BGM',
            uphone: '電話・FAX',
            uplink: '店舗アプリ',
            uregi: 'POSレジ',
            uspot: '業務用フリーWi-Fi',
        },
    },
    //契約書種別
    contract: {
        kind: {
            apply: 'apply',
            hitosara: 'hitosara',
            delivery: 'delivery',
            change: 'change',
            report: 'report',
        },
        name: {
            apply: 'USENサービス加入申込書 著作物使用許諾申込書',
            hitosara: 'ヒトサラ加盟店登録及び即時予約機能申込書 兼 関連サービス申込書',
            delivery: '納品書・物品受領書',
            change: 'デジタル方式切替承諾 兼サービス変更申込書 ',
            report: '作業完了報告書',
        },
    },
    services: [
        {
            id: '10',
            title: '店舗BGM',
            hintTitle: '通信機能搭載の定番BGMや1曲ずつカスタムできるBGMアプリをご用意',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '11',
            title: '防犯・クラウドカメラ',
            hintTitle: 'ローカル録画や来店客分析できるAIカメラもご用意',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '12',
            title: '電話・FAX',
            hintTitle: '電話とFAXが1台になったIP電話',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '13',
            title: 'インターネット・Wi-Fi',
            hintTitle: '工事不要タイプ・光回線・来店客用Wi-Fiをご用意',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '14',
            title: 'タブレットPOSレジ',
            hintTitle: '4業種に特化したタブレットPOSレジ',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '15',
            title: 'オーダーシステム',
            hintTitle: 'モバイルオーダー・テーブルオーダーなどレジと組み合わせて省人化',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '16',
            title: '券売機・セルフ精算機',
            hintTitle: '注文から会計までお客様が行うタッチパネル型券売機',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '17',
            title: '勤怠管理システム',
            hintTitle: 'シフト作成もできる、クラウド型打刻勤怠管理システム',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '18',
            title: 'デジタルサイネージ',
            hintTitle: '屋外設置から卓上サイズのタブレット型までご用意',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '19',
            title: '決済端末',
            hintTitle: 'クレジット、電子マネー、QRコード決済全て利用可能',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '20',
            title: '店舗アプリ作成',
            hintTitle: '来店促進・リピーター集客の販促ツール',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '21',
            title: 'カード型インカム',
            hintTitle: '超薄型＆超軽量！ポケットに入るインカム',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '22',
            title: '分煙ブース',
            hintTitle: 'お店のサイズに合わせて喫煙スペース設置',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '23',
            title: 'グルメサイト掲載',
            hintTitle: 'ヒトサラ・食べログ等の集客サービスを多数ご用意',
            icon: 'homeicon_Uregister.svg',
        },
        {
            id: '24',
            title: '求人掲載 Indeed',
            hintTitle: '求人広告制作・掲載から管理代行まで一気通貫の採用支援',
            icon: 'homeicon_Uregister.svg',
        },
    ],
};
