import axios from 'axios';
import axiosRetry from 'axios-retry';

export const ssoClient = axios.create({
    baseURL: process.env.VUE_APP_API_SSO_URL,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'json',
});

axiosRetry(ssoClient, { retries: 3 });

export default ssoClient;
